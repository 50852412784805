import React, { Component } from 'react';

import { NavbarBrand } from 'reactstrap';

class TopNavBarLogo extends Component {
	render() {
		return (
			<NavbarBrand>
				<img
					style={{
						position: 'absolute',
						top: '3px',
					}}
					src='/images/home_logo.png'
				/>
			</NavbarBrand>
		);
	}
}

export default TopNavBarLogo;
