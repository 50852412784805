import React, { Component } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DrawModal extends Component {
	constructor() {
		super();

		this.state = {
			isOpen: true,
		};
	}

	toggle() {
		let st = true;

		if (!this.props.dontAllowClose) {
			st = !this.state.isOpen;
		}

		this.setState({ isOpen: st });

		if (!st) {
			this.props.m.close(this.props.id);
		}
	}

	onClosed = () => {
		if (this.props.onClose) {
			this.props.onClose(this.props.id);
		}
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.isOpen !== !nextProps.close) {
			return {
				isOpen: !nextProps.close,
			};
		}

		return null;
	}

	render() {
		return (
			<Modal
				isOpen={this.state.isOpen}
				toggle={this.toggle.bind(this)}
				className={this.props.className}
				zIndex={10000}
				onClosed={this.onClosed}
			>
				{this.props.header && (
					<ModalHeader
						toggle={this.props.dontAllowClose ? null : this.toggle.bind(this)}
					>
						{this.props.header}
					</ModalHeader>
				)}
				{this.props.body && <ModalBody>{this.props.body}</ModalBody>}
				{!this.props.noFooter && (
					<ModalFooter>
						{!this.props.dontAllowClose &&
							(this.props.footer ? (
								this.props.footer
							) : (
								<button
									type='button'
									className='btn btn-default'
									onClick={this.toggle.bind(this)}
								>
									Close
								</button>
							))}
					</ModalFooter>
				)}
			</Modal>
		);
	}
}

export default DrawModal;
