import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import FooterNavBar from 'resources/assets/react-app/components/FooterNavBar';

class Footer extends Component {
	render() {
		const { user, location } = this.props;

		return (
			<nav className='navbarBottom navbar navbar-dark'>
				{location.pathname.match(/^\/getStarted/) ? (
					<ul className='nav navbar-nav'>
						<li>&nbsp;</li>
					</ul>
				) : (
					<FooterNavBar />
				)}
			</nav>
		);
	}
}

export default withRouter(Footer);
