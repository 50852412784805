import config from 'resources/assets/react-app/config';

let Http = {
	get: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'GET', data, fetchConfig, formData);
	},
	post: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'POST', data, fetchConfig, formData);
	},
	put: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'PUT', data, fetchConfig, formData);
	},
	delete: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'DELETE', data, fetchConfig, formData);
	},
};

function DMFetch(url, method, data, fetchConfig, formData) {
	if (!fetchConfig) fetchConfig = {};

	if (config && config.dataDomain) {
		url = config.dataDomain + url;
	}

	var headers = {};

	headers['X-Requested-With'] = 'XMLHttpRequest';

	let user = window.reduxStore.getState().user;

	if (user && user.currentTeam) {
		headers['X-Current-Team'] = user.currentTeam.id;
	}

	headers['X-Socket-ID'] = user.socketId;

	headers['Accept'] = 'application/json';

	if (method != 'GET' && data) {
		headers['Content-Type'] = 'application/json';
	}

	if (data && formData) {
		throw new Error('Can not have both json and formdata in a request');
	}

	if (data) {
		data = JSON.stringify(data);
	} else if (formData instanceof FormData) {
		data = formData;
	}

	let loc = new URLSearchParams(document.location.search);

	if (loc.get('noLogin')) {
		let url1 = url.split('?');

		if (!url1[1]) {
			url1[1] = '';
		}

		url1[1] = 'noLogin=1&' + url1[1];

		url = url1.join('?', url1);
	}

	return fetch(url, {
		method: method,
		headers: headers,
		credentials: 'include', //'same-origin',
		body: data,
	}).then((response) => {
		if (response.status == 401) {
			let se = window.location.search;

			se = se.replace('noLogin=1', '');

			document.location =
				'/login?redi=' + encodeURIComponent(document.location.pathname + se);
			return {};
		}

		if (response.status == 406) {
			document.location = '/logout';
			return {};
		}

		if (Math.floor(response.status / 100) == 4) {
			return {};
		}

		if (fetchConfig.returnResponse) {
			return response;
		}

		if (response.ok) {
			if (fetchConfig.returnAllData) {
				return responseToJson(response);
			}

			return returnDataFromResponse(response);
		}

		return {};
	});
}

function returnDataFromResponse(response) {
	return responseToJson(response).then((data) => {
		if (data.errors) {
			for (let i = 0; i < data.errors.length; i++) {
				import(/* webpackChunkName: "error-modal" */ './errorModal').then(
					(errorModal) => {
						errorModal.default(data.errors[i].message);
					}
				);
			}
		}

		return data.data;
	});
}

function responseToJson(response) {
	try {
		return response.json();
	} catch (e) {
		return { data: {} };
	}
}

export default Http;
