import asyncComponent from 'Demora/AsyncComponent';

//import CRMAuthRoutes from '../../../../vendor/z5internet/CRM/src/resources/assets/js/Routes/authRoutes';

const Email = asyncComponent(() =>
	import(
		/* webpackChunkName: "z5acad-email" */ '../../js/Components/Email/index'
	),
);

const Learn = asyncComponent(() =>
	import(/* webpackChunkName: "z5acad-learn" */ '../../js/Components/Learn'),
);

// const Read = asyncComponent(() =>
//	import(/* webpackChunkName: "z5acad-plan" */ '../../js/Components/Read/read'),
//);

const Plan = asyncComponent(() =>
	import(/* webpackChunkName: "prot-auth-plan" */ '../../js/Components/Plan/'),
);

const PlanIntro = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-planIntro" */ '../../js/Components/Plan/tabs/Intro/planIntro'
	),
);

export default [
	{
		path: '/home/email',
		component: Email,
	},
	{
		path: '/home/learn',
		component: Learn,
	},
	{
		path: '/home/learn/:page',
		component: Learn,
	},
	/*
	{
		path: '/home/read',
		component: Read,
	},
	{
		path: '/home/read/:page',
		component: Read,
	},
*/
	{
		path: '/plan',
		component: Plan,
	},
	{
		path: '/plan/:setup_page',
		component: Plan,
	},
]; //.concat(CRMAuthRoutes);
