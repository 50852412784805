let config = {
	/*

		Add tabs to settings page

		[

			{
				title:'Tab title',
				url:'url', "/settings/**URL**"
				component: (<Component />),
			},

		];

	*/

	title: 'Z5 Plan',

	settingsTabs: [],

	addons: [],

	dataDomain:
		typeof document === 'undefined'
			? ''
			: document.location.port == 8080
			? 'http://z5traffic.test:8000'
			: '',

	graphQL: true,
};

export default config;
