import React, { Component } from 'react';

import ReduxConnect from 'Demora/redux-connect';

import { withRouter } from 'react-router-dom';

class PreHeader extends Component {
	componentDidMount() {
		const { user, location } = this.props;

		if (user.id) {
			const plan = user.finishedGetStarted.plan;

			const pathname = location.pathname;

			const isLearn3 = pathname.match(/^\/home\/learn\/3/);

			if (!isLearn3 && plan.cj_upgrade && !plan.lt_stuff) {
				props.history.push('/home/learn/3');
			}
		}
	}

	render() {
		return null;
	}
}

export default withRouter(
	ReduxConnect(PreHeader, {
		user: 'user',
	}),
);
