import loadjs from 'loadjs';

import LP from './livePusher';

import Http from './utils/http';

import config from 'resources/assets/react-app/config';

class pusher {
	constructor() {
		this.cipns = null;
		this.currentSocketId = null;
		this.gettingAuth = {};
	}

	livePusherChannel = (channel) => {
		return this.livePusherListen('channel', channel);
	};

	livePusherPrivate = (channel) => {
		return this.livePusherListen('private', channel);
	};

	livePusherPresence = (channel) => {
		return this.livePusherListen('presence', channel);
	};

	livePusherLeave = (channel, event) => {
		if (this.livepusher) {
			this.livepusher.leave(channel);
		}
	};

	livePusherListen = (action, channel) => {
		let that = this;

		return {
			action: action,
			channel: channel,
			whisper: function (event, callback) {
				return that.eee(this, event, callback, 'whisper');
			},
			listenForWhisper: function (event, callback) {
				return that.eee(this, event, callback, 'listenForWhisper');
			},
			listen: function (event, callback) {
				return that.eee(this, event, callback, 'listen');
			},
		};
	};

	eee(that, event, callback, action2) {
		const lp3 = window.reduxStore.getState().website.livePusher;

		if (lp3 && lp3.app_id) {
			if (!this.cipns) {
				this.cipns = this.checkIfPusherNeedsStarting()
					.then(() => {
						this.gotCIPNS(that.action, that.channel, event, callback, action2);
					})
					.catch(() => {});
			} else {
				this.cipns
					.then(() => {
						this.gotCIPNS(that.action, that.channel, event, callback, action2);
					})
					.catch(() => {});
			}
		}

		return this.livePusherListen(that.action, that.channel);
	}

	gotCIPNS(action1, channel, event, callback, action2) {
		return this.livepusher[action1](channel)[action2](event, callback);
	}

	saveSocketId(socketId) {
		window.reduxStore.dispatch({
			type: 'STORE_USER',
			user: {
				socketId: socketId,
			},
		});
	}

	checkIfPusherNeedsStarting = () => {
		const that = this;

		const promise = new Promise((resolve, reject) => {
			const state = window.reduxStore.getState();

			const user = state.user;

			const website = state.website;

			if (that.livepusher) {
				resolve(true);
			} else {
				const host = 'https://api.livepusher.com:6001';

				loadjs(host + '/livePusher/socket.io.js', {
					success: () => {
						that.livepusher = new LP({
							broadcaster: 'socket.io',
							host: host,
							path: '/livePusher',
							auth: {},
							appId: website.livePusher.app_id,
							authPrivate: (socketId, channel) => {
								this.currentSocketId = socketId;

								if (
									!that.gettingAuth[channel] ||
									that.gettingAuth[channel].length == 0
								) {
									that.gettingAuth[channel] = [];

									Http.post('/data/broadcasting/auth', {
										socket_id: socketId,
										channel_name: channel,
									}).then((data) => {
										that.gettingAuth[channel].map((v) => {
											if (this.currentSocketId == v.socketId) {
												v.resolve(data.auth);
											} else {
												v.reject('Failed - cannot get pusher auth');
											}
										});

										that.gettingAuth[channel] = [];
									});
								}

								const a = new Promise((resolve, reject) => {
									that.gettingAuth[channel].push({
										resolve,
										reject,
										socketId,
									});
								});

								return a.catch(function () {});
							},
						});

						resolve(true);
					},

					error: (pathsNotFound) => {},

					numRetries: 99,
				});
			}
		});

		return promise;
	};
}

let pus = new pusher();

export default {
	channel: pus.livePusherChannel,
	private: pus.livePusherPrivate,
	leave: pus.livePusherLeave,
	presence: pus.livePusherPresence,
};
