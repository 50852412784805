import React from 'react';

import http from './utils/http';

class ErrorBoundary extends React.Component {
	constructor() {
		super();

		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
		};
	}

	componentDidCatch(error, info) {
		http.post('/data/log_error', {
			stacktrace: error.toString() + '\n' + error.stack,
			url: window.location.href,
			type: 'client',
		});
	}

	render() {
		let state = this.state;

		if (state.hasError) {
			let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

			if (state.IE) {
				return (
					<h3>
						<center>
							<a href='http://outdatedbrowser.com'>
								Unfortunately your browser is too old to work with our website.
								<br />
								We recommend you install one of the latest browsers by clicking
								here.
							</a>
							.
						</center>
					</h3>
				);
			}

			return (
				<div className='container'>
					<div className='jumbotron mt-3'>
						<h1>Error</h1>
						<p className='lead'>
							Sorry, you've found an error and we'll work on getting it fixed.
						</p>
						<a className='btn btn-lg btn-primary' href='/' role='button'>
							Home
						</a>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
