import React from 'react';
import { render } from 'react-dom';

import 'babel-polyfill';

/** CSS **/

import 'resources/assets/react-app/sass/demora.scss';
import 'resources/assets/react-app/css/app.css';
import '../../css/global.css';
import '../../css/toastr.css';
import '../../css/animate.css';

import config from 'resources/assets/react-app/config';

import loadinggif from '../../../images/loading2.gif';

/** Redux **/

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers';

import http from './utils/http';

import PropTypes from 'prop-types';

import pusher from './pusher';

import ErrorBoundary from './errorBoundary';

import { BrowserRouter } from 'react-router-dom';

import App1 from './components/App';

class App extends React.Component {
	getChildContext() {
		return { pusher: pusher };
	}

	constructor(props, context) {
		super(props, context);

		this.store = createStore(reducers);

		window.reduxStore = this.store;

		this.state = {
			loaded: false,
		};
	}

	componentDidMount() {
		try {
			if (window.top == window && window.console) {
				setTimeout(
					console.log.bind(
						console,
						'%c%s',
						'color: red; background: yellow; font-size: 24px;',
						'WARNING!'
					)
				);

				setTimeout(
					console.log.bind(
						console,
						'%c%s',
						'font-size: 18px;',
						'Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.\nDo not enter or paste code that you do not understand.'
					)
				);
			}
		} catch {}

		let gets = [];

		if (!window.fetch) {
			gets.push(import('whatwg-fetch'));
		}

		if (!window.URLSearchParams || !new URLSearchParams().get) {
			gets.push(import('url-search-params-polyfill'));
		}

		Promise.all(gets).then((file) => {
			this.loadStart();
		});

		// https://github.com/facebook/react/issues/11538#issuecomment-417504600
		// https://bugs.chromium.org/p/chromium/issues/detail?id=872770

		if (typeof Node === 'function' && Node.prototype) {
			const originalRemoveChild = Node.prototype.removeChild;
			Node.prototype.removeChild = function (child) {
				if (child.parentNode !== this) {
					if (console) {
						console.error(
							'Cannot remove a child from a different parent',
							child,
							this
						);
					}
					return child;
				}
				return originalRemoveChild.apply(this, arguments);
			};

			const originalInsertBefore = Node.prototype.insertBefore;
			Node.prototype.insertBefore = function (newNode, referenceNode) {
				if (referenceNode && referenceNode.parentNode !== this) {
					if (console) {
						console.error(
							'Cannot insert before a reference node from a different parent',
							referenceNode,
							this
						);
					}
					return newNode;
				}
				return originalInsertBefore.apply(this, arguments);
			};
		}
	}

	loadStart() {
		http.get('/data/start').then((data) => {
			if (!data.rufP) {
				return;
			}
			if (data.website) {
				document.title = data.website.name;

				this.store.dispatch({
					type: 'STORE_WEBSITE',
					website: data.website,
				});
			}

			this.store.dispatch({
				type: 'STORE_USER',
				user: data.user,
			});

			if (data.users || data.uiNotifications) {
				this.store.dispatch({
					type: 'addUiNotifications',
					data: data,
				});
			}

			this.setState({
				loaded: true,
			});
		});
	}

	render() {
		if (!this.state.loaded) {
			return (
				<div className='text-center'>
					<img src={loadinggif} style={{ maxWidth: '250px' }} />
					<br />
					<h3>Loading</h3>
				</div>
			);
		}

		return (
			<Provider store={this.store}>
				<BrowserRouter>
					<App1 />
				</BrowserRouter>
			</Provider>
		);
	}
}

App.childContextTypes = {
	pusher: PropTypes.object,
};

render(
	<ErrorBoundary>
		<App />
	</ErrorBoundary>,
	document.getElementById('appRoot')
);
