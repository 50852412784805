import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const dark = 'hsl(200, 20%, 20%)';
const light = '#bbb';
const styles = {};

styles.link = {
	fontWeight: 200,
};

class FooterNavBar extends Component {
	render() {
		let cols = 'col-3 col-md-3';

		return (
			<div style={{ flexGrow: 1 }}>
				<div className='d-flex'>
					<div className='row text-center mx-auto'>
						<div className={cols}>
							<Link to='/contact' className='text-white'>
								Contact
							</Link>
						</div>
						<div className={cols}>
							<Link to='/terms' className='text-white'>
								Terms
							</Link>
						</div>
						<div className={cols}>
							<Link to='/privacy' className='text-white'>
								Privacy
							</Link>
						</div>
						<div className={cols}>
							<Link to='/blog' className='text-white'>
								Blog
							</Link>
						</div>
					</div>
				</div>
				<div className='text-center mx-auto'>
					<small style={{ color: '#ffffff' }}>
						Copyright &copy; Z5 Internet Ltd 2009-
						{new Date().getFullYear()}
					</small>
				</div>
			</div>
		);
	}
}

export default FooterNavBar;
