import { combineReducers } from 'redux';

import config from 'resources/assets/react-app/config';

import userReducers from 'resources/assets/react-app/reducers';

import extend from './utils/extend';

import headerFooterHeight from './utils/headerFooterHeight';

import iosInnerHeight from 'ios-inner-height';

let combined = {};

let reqc = require.context(
	'../../../../../../',
	true,
	/^\.\/[^\/]*\/src\/resources\/assets\/js\/src\/reducers.js$/
);

if (config && config.addons) {
	for (let i = 0; i < config.addons.length; i++) {
		let reduc = reqc(
			'./' + config.addons[i] + '/src/resources/assets/js/src/reducers.js'
		).default;

		for (let ii in reduc) {
			combined[ii] = reduc[ii];
		}
	}
}

if (userReducers) {
	for (let ii in userReducers) {
		combined[ii] = userReducers[ii];
	}
}

const user = (state = {}, action) => {
	switch (action.type) {
		case 'STORE_USER':
			state = extend(state, action.user);

			return Object.assign({}, state);

		case 'REPLACE_USER':
			state = action.user;

			return Object.assign({}, state);

		case 'LOGOUT':
			return {};

		case 'STORE_TEAM':
			state.multiAccounts[action.team.id] = action.team;

			return Object.assign({}, state);

		case 'DELETE_TEAM':
			delete state.multiAccounts[action.tid];

			return Object.assign({}, state);

		case 'DELETE_TEAM_USER':
			delete state.multiAccounts[action.tid].members[action.memberType][
				action.id
			];

			return Object.assign({}, state);

		case 'STORE_TEAM_MEMBERS':
			let types = { active: 1, invited: 1 };

			for (let type in types) {
				for (let i in action.users[type]) {
					let user = action.users[type][i];

					let tid = user.tid;

					let team = state.multiAccounts[tid];

					if (!team.members) {
						team.members = {};
					}

					if (!team.members[type]) {
						team.members[type] = {};
					}

					team.members[type][user.uid] = user;
				}
			}

			return Object.assign({}, state);

		default:
			return state;
	}
};

const website = (state = [], action) => {
	switch (action.type) {
		case 'STORE_WEBSITE':
			return Object.assign({}, action.website);

		default:
			return state;
	}
};

const toastr = (state = [], action) => {
	switch (action.type) {
		case 'TOASTR':
			return action.message.slice(0);

		default:
			return state;
	}
};

const modals = (state = {}, action) => {
	switch (action.type) {
		case 'addModal':
			state[action.modal.id] = action.modal;

			return Object.assign({}, state);

		case 'closeModal':
			if (state[action.modal]) {
				state[action.modal].close = true;
			}

			return Object.assign({}, state);

		default:
			return state;
	}
};

const referrals = (state = {}, action) => {
	switch (action.type) {
		case 'saveReferralData':
			let d = action.data;

			for (let k in d) {
				if (k != 'data') {
					state[k] = d[k];
				}
			}

			if (!state.data) {
				state.data = [];
			}

			for (let i = 0; i < d.data.length; i++) {
				state.data[d.from + i - 1] = d.data[i];
			}

			return Object.assign({}, state);

		default:
			return state;
	}
};

const uiNotifications = (state = {}, action) => {
	switch (action.type) {
		case 'addUiNotifications':
			state = extend(state, action.data.uiNotifications);

			return Object.assign({}, state);

		case 'modifyUiNotifications':
			Object.keys(action.data.uiNotifications).map((nid) => {
				Object.keys(state.notifications).map((v, k) => {
					if (v == nid) {
						state.notifications[v] = Object.assign(
							state.notifications[v],
							action.data.uiNotifications[nid]
						);
					}
				});
			});

			return Object.assign({}, state);

		default:
			return state;
	}
};

const users = (state = {}, action) => {
	switch (action.type) {
		case 'STORE_FEED':
		case 'STORE_USERS':
		case 'UPDATE_FEED':
		case 'addUiNotifications':
		case 'addUsers':
			if (!action.data.users) {
				return state;
			}

			extend(state, action.data.users);

			/**
        	if (action.data.users) {

	        	for(var i in action.data.users) {

	        		let o = action.data.users[i];

	        		o.obj = i;

	        		state[action.data.users[i].u] = o;

	        	}

        	}
**/
			return Object.assign({}, state);

		default:
			return state;
	}
};

const usersByUsernames = (state = {}, action) => {
	switch (action.type) {
		case 'STORE_FEED':
		case 'addUiNotifications':
			let users = action.data.users;

			if (users) {
				let ids = Object.keys(users);

				for (let i = 0; i < ids.length; i++) {
					let id = ids[i];

					if (users[id]) {
						state[users[id].u] = id;
					}
				}
			}

			return Object.assign({}, state);

		default:
			return state;
	}
};

const heightOfInnerInnerWindow = (state = '', action) => {
	switch (action.type) {
		case 'SET_WINDOW_HEIGHT_100':
			let el = document.getElementById('routerRoot');

			if (!el) {
				return '';
			}

			let divH =
				iosInnerHeight() -
				el.getBoundingClientRect().top -
				headerFooterHeight().footer +
				14;

			let el1 = document.getElementsByTagName('body')[0];

			el1.style.minHeight = 'auto';
			el1.style.position = 'fixed';
			el1.style.width = '100vw';

			return divH + 'px';

		case 'CLEAR_WINDOW_HEIGHT':
			let el2 = document.getElementsByTagName('body')[0];

			el2.style.minHeight = '';
			el2.style.position = '';
			el2.style.width = '';

			return '';

		default:
			return state;
	}
};

const referer = (state = null, action) => {
	switch (action.type) {
		case 'RECORD_REFERER_USERNAME':
			return action.referer;

		default:
			return state;
	}
};

combined.user = user;
combined.users = users;
combined.usersByUsernames = usersByUsernames;
combined.website = website;
combined.toastr = toastr;
combined.modals = modals;
combined.referrals = referrals;
combined.referer = referer;
combined.uiNotifications = uiNotifications;
combined.heightOfInnerInnerWindow = heightOfInnerInnerWindow;

const reducers = combineReducers(combined);
export default reducers;
