import asyncComponent from 'Demora/AsyncComponent';

//import CRMOpenRoutes from '../../../../vendor/z5internet/CRM/src/resources/assets/js/Routes/openRoutes';

const HomePage = asyncComponent(() =>
	import(
		/* webpackChunkName: "z5acad-home" */ '../../js/Components/home_page/HomePage_old'
	),
);

const Signup = asyncComponent(() =>
	import(
		/* webpackChunkName: "specialinvite" */ '../../js/components/SpecialSignupForm'
	),
);

export default [
	{
		path: '/signup/specialinvite',
		component: Signup,
	},
	{
		path: '/indold',
		component: HomePage,
	},
]; //.concat(CRMOpenRoutes);
